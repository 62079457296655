import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import ArticleBox from '../components/ArticleBox'
import styles from "./index.module.scss"

// http://ganlanyuan.github.io/tiny-slider/
import "tiny-slider/src/tiny-slider.scss" //js loaded in componentDidMount()

class BlogIndex extends React.Component {

  createClosingImageSlider = (event) => {
    let parentEl = event ? event.target : document
    let wrapper = parentEl.querySelectorAll('#closingImagesWrapper')[0]

    if (typeof this.tns !== 'undefined' && !this.tnsInitialized && !!wrapper) {
      this.tnsInitialized = true

      this.tns({
        container: wrapper,
        items: 1,
        edgePadding: null,
        autoHeight: true,
        loop: true,
        nav: false,
        gutter: null,
        controlsText: ["←", "→"],
      })
    }
  }

  componentDidMount() {
    import('tiny-slider/src/tiny-slider.module.js')
    .then(({tns}) => {
      this.tns = tns
      this.createClosingImageSlider()
      document.body.addEventListener('DOMSubtreeModified', this.createClosingImageSlider)
    })
  }

  componentWillUnmount() {
    document.body.removeEventListener('DOMSubtreeModified', this.createClosingImageSlider)
  }

  render() {
    const defaultMetadata = this.props.data.datoCmsSite.globalSeo
    const pageTitle = defaultMetadata.siteName + defaultMetadata.titleSuffix
    const pageDescription = defaultMetadata.fallbackSeo.description
    //const homePageSettings = this.props.data.allDatoCmsNyitoBeallitasok.edges[0].node
    const closingImages = this.props.data.allDatoCmsZaroKepek.edges.reverse()

    const posts = this.props.data.allDatoCmsNyitoBeallitasokV2.edges[0].node.nyitoCikkekV2

    return (
      <Layout location={this.props.location} readOnlyAPIToken={this.props.data.site.siteMetadata.readOnlyAPIToken}>

        <Helmet
          htmlAttributes={{ lang: 'hu' }}
          meta={[{ name: 'description', content: pageDescription }]}
          title={pageTitle}
        />

        <div className={styles.topWrapper}>

          {posts.map(({ id, cim, lead, cikk, szerzo, kep }, index) => { return (
            <>
            {index == 0 && <section className={styles.highlighted}>
              <ArticleBox
                type="highlighted"
                cim={cim.replace(/\n/g, '<br/>')}
                lead={lead.replace(/\n/g, '<br/>')}
                linkTo={cikk.slug}
                image={kep}
                szerzo={cikk.szerzo}
              />
            </section>}
            </>
          )})}

          <section className={styles.importantPosts}>
            {posts.map(({ id, cim, lead, cikk, szerzo }, index) => { return (
              <>
                {(index == 2 || index == 3 || index == 4) && <hr/>}
                {index > 0 && index < 5 && <ArticleBox
                  type="important"
                  cim={cim.replace(/\n/g, '<br/>')}
                  lead={lead.replace(/\n/g, '<br/>')}
                  linkTo={cikk.slug}
                  szerzo={cikk.szerzo} />
                }
              </>
            )})}
          </section>
        </div>

        <section className={styles.otherPosts}>
          {posts.map(({ id, cim, lead, cikk, szerzo }, index) => { return (
            <>
              {index > 4 && index < 8 && <ArticleBox
                type="other"
                cim={cim.replace(/\n/g, '<br/>')}
                lead={lead.replace(/\n/g, '<br/>')}
                linkTo={cikk.slug}
                image={cikk.fejleckep}
                szerzo={cikk.szerzo} />
              }
            </>
          )})}
        </section>

        <section className={styles.quoteBlock}>
          {posts.map(({ id, cim, lead, cikk, szerzo, idezet }, index) => { return (
            <>
              {index == 8 && <Link to={'/' + cikk.slug}>
                  <div className={styles.quoteText}><q>{idezet}</q></div>
                  <p className={styles.quoteLink}>{cim}</p>
                </Link>
              }
            </>
          )})}
        </section>

        <section className={styles.otherPosts}>
          {posts.map(({ id, cim, lead, cikk, szerzo }, index) => { return (
            <>
              {index > 8 && index < 15 && <ArticleBox
                type="other"
                cim={cim.replace(/\n/g, '<br/>')}
                lead={lead.replace(/\n/g, '<br/>')}
                linkTo={cikk.slug}
                image={cikk.fejleckep}
                szerzo={cikk.szerzo} />
              }
            </>
          )})}
        </section>


{/*Záró kép*/}
        <section className={styles.closingImages}>
          <div id="closingImagesWrapper" className={styles.closingImagesWrapper}>
            {closingImages.map(({node}) => {

              return (<div key={node.id} className={styles.closingImage}>
                  { node.zaroKepHivatkozasa && <a href={node.zaroKepHivatkozasa} target="_blank">
                    <div className={styles.imageWrapper}>
                      <img className={styles.image} src={node.zarokep.url}/>
                      <img className={styles.mobileImage} src={node.mobilKep.url}/>
                      { node.kepForrasa && <span className={styles.closinImageSource}>Fotó: {node.kepForrasa}</span>}
                    </div>
                    {node.idezet && <div className={styles.quote}><q dangerouslySetInnerHTML={{ __html: node.idezet.replace(/\n/g, '<br/>')}}/></div>}
                    {node.idezetSzerzoje && <p className={styles.quoteFrom}>{node.idezetSzerzoje}</p>}
                  </a> }
                  { !node.zaroKepHivatkozasa && <a>
                    <div className={styles.imageWrapper}>
                      <img className={styles.image} src={node.zarokep.url}/>
                      <img className={styles.mobileImage} src={node.mobilKep.url}/>
                      { node.kepForrasa && <span className={styles.closinImageSource}>Fotó: {node.kepForrasa}</span>}
                    </div>
                    {node.idezet && <div className={styles.quote}><q dangerouslySetInnerHTML={{ __html: node.idezet.replace(/\n/g, '<br/>')}}/></div>}
                    {node.idezetSzerzoje && <p className={styles.quoteFrom}>{node.idezetSzerzoje}</p>}
                  </a> }
                </div>)
            })}
          </div>
        </section>

        <section className={styles.otherPosts}>
          {posts.map(({ id, cim, lead, cikk, szerzo }, index) => { return (
            <>
              {index > 14 && index < 21 && <ArticleBox
                type="other"
                cim={cim.replace(/\n/g, '<br/>')}
                lead={lead.replace(/\n/g, '<br/>')}
                linkTo={cikk.slug}
                image={cikk.fejleckep}
                szerzo={cikk.szerzo} />
              }
            </>
          )})}
        </section>


        <section className={styles.quoteBlock}>
          {posts.map(({ id, cim, lead, cikk, szerzo, idezet }, index) => { return (
            <>
              {index == 21 && <Link to={'/' + cikk.slug}>
                  <div className={styles.quoteText}><q>{idezet}</q></div>
                  <p className={styles.quoteLink}>{cim}</p>
                </Link>
              }
            </>
          )})}
        </section>
      </Layout>
    )
  }
}

export default BlogIndex

// export const disableCorePrefetching = () => true

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }
    datoCmsSite {
      globalSeo {
        siteName
        titleSuffix
        fallbackSeo {
          title
          description
        }
      }
    }
    allDatoCmsNyitoBeallitasokV2 {
      edges {
        node {
          nyitoCikkekV2 {
            id
            cim
            lead
            idezet
            kep {
              path
              url
            }
            cikk {
              id
              fejleckep {
                path
                url
              }
              szerzo
              slug
            }
          }
        }
      }
    }
    allDatoCmsZaroKepek (
      filter: {
        megjelenikAGaleriaban: { eq: true }
      }
    ) {
      edges {
        node {
          id
          zarokep {
            url
          }
          mobilKep {
            url
          }
          kepForrasa
          zaroKepHivatkozasa
          position
          idezet
          idezetSzerzoje
        }
      }
    }
  }
`
