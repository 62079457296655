import React from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styles from "./articlebox.module.scss"

class ArticleBox extends React.Component {
  render() {
    if (this.props.type == "highlighted") {
      return (
        <article className={styles.highlightedContainer}>
          <div className={styles.contentWrapper}>
            <Link to={"/" + this.props.linkTo}>
              <img className={styles.image} src={this.props.image.url}/>

              <div className={styles.content}>
                <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.cim}} />
                <p className={styles.lead} dangerouslySetInnerHTML={{ __html: this.props.lead}} />
                <p className={styles.author}>Szerző: {this.props.szerzo}</p>
              </div>
            </Link>
          </div>
        </article>
      )
    } else if (this.props.type == "important") {
      return (
        <article className={styles.importantContainer}>
          <Link to={"/" + this.props.linkTo}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.cim}} />
            <p className={styles.lead} dangerouslySetInnerHTML={{ __html: this.props.lead}} />
            <p className={styles.author}>Szerző: {this.props.szerzo}</p>
          </Link>
        </article>
      )
    } else if (this.props.type == "other") {
      return (
        <>
          {this.props.titleBefore && <div className={styles.sectionTitle}>{this.props.titleBefore}</div>}

          <article className={styles.otherContainer}>
            <Link to={"/" + this.props.linkTo}>
              {this.props.image
                && <picture>
                      <source srcset={this.props.image.url + '?w=720&q=20'} media="(max-width: 1920px)"/>
                      <img className={styles.image} src={this.props.image.url + '?w=100&q=10'}/>
                  </picture>}

              <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: this.props.cim}} />
              <p className={styles.lead} dangerouslySetInnerHTML={{ __html: this.props.lead}} />
              <p className={styles.author}>Szerző: {this.props.szerzo}</p>
            </Link>
          </article>
        </>
      )
    }
  }
}

export default ArticleBox
